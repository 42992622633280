<template>
    <div class="h-90 ml-2 mr-2">
        <div class="row align-items-center justify-content-center" v-if="ajax_call_in_progress">
            <div class="col-lg-12 py-5 px-3 mt-3">
                <div class="d-flex align-items-center justify-content-center">
                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                </div>
            </div>
        </div>
        <div v-else class="pb-10 h-100">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                <div>
                    <div class="breadcrumbactive">
                        <div >
                            <span class="pointer" @click="goToCompanyList">Company</span>
                            <span class="pointer" @click="goToListView">Business Unit</span>
                            <div>View</div>
                        </div>
                    </div>
                </div>
                <div>
                    <button v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="btn btn-new-secondary ml-5 float-right" @click="goToEditView">Edit</button>
                </div>
            </div>
            <!-- <div class="d-flex align-items-center justify-content-between" >
                <div class="d-flex align-items-center" >
                    <div class="header-slant-tab">
                        <span class="text-secondary fs-14">View</span>
                    </div>
                    <div class="header-slant-tab-border"></div>
                </div>
                
            </div> -->
            <div class="create-company-container w-100 h-100" style="border-top-left-radius: 0;">
                <div class="text-center fw-600 mb-3">
                    <span class="text-secondary fs-16">View</span>
                </div>
                <div class="create-company-header d-flex align-items-center justify-content-between">
                    <span class="fs-14 pl-4 text-white">Business Unit Information</span>
                    <span class="pointer" @click="showHideContent">
                        <i class="text-white" :class="{'icon-chevron-double-down':is_show_hide, 'icon-chevron-double-up':!is_show_hide}" style="font-size: 20px;"></i>
                    </span>
                </div>
                <div v-if="is_show_hide" class="mt-6 px-6">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                            <div class="w-100">
                                <label class="input-label">Company Name</label>
                                <div class="d-flex align-items-center">
                                    <div class="input-field" >
                                        {{bus_edit_data.company_name}}
                                    </div>
                                    <span class="pointer" >
                                        <i class="icon icon-check-circle fs-30 mt-9 ml-2" style="color: rgb(18, 136, 7);"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                            <div class="w-100">
                                <label class="input-label">Business Unit Name</label>
                                <div class="d-flex align-items-center">
                                    <div class="input-field">
                                        {{bus_edit_data.name}}
                                    </div>
                                </div>
                                <span class="invalid-feedback-form text-danger" v-show="errors.has('business_unit_name')">
                                    {{errors.first('business_unit_name')}}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                            <div class="w-100">
                                <label class="input-label">Country</label>
                                <div class="d-flex align-items-center">
                                    <div class="input-field">
                                        {{bus_edit_data.country}}
                                    </div>
                                    <span class="pointer" >
                                        <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                        </el-tooltip>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row">
                                <div class="col-lg-5 col-md-6 col-sm-4 mt-5">
                                    <div class="w-100">
                                        <span class="input-label">Started On (DD/MM/YYYY)</span>
                                        <div class="d-flex align-items-center mt-3">
                                            <div class="input-field">
                                                {{bus_edit_data.started_on}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-6 col-sm-8 mt-5">
                                    <div class="w-100 position-relative">
                                        <span class="input-label">Business Unit Code</span>
                                        <div class="d-flex align-items-center mt-3">
                                            <div class="input-field">
                                                {{bus_edit_data.code}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 mt-5">
                            <div class="w-100">
                                <label class="input-label">Description</label>
                                <textarea rows="5" type="text" maxlength="4000" :readonly="true" class="input-field input-textarea" v-model="bus_edit_data.description" name="description" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {HalfCircleSpinner} from 'epic-spinners';
import companies from '../mixins/companies';
export default {
    components: { HalfCircleSpinner },
    mixins: [companies],
    data() {
        return {
            ajax_call_in_progress: false,
            bus_edit_data: {},
            is_show_hide: true,
        }
    },
    methods: {
        showHideContent() {
            this.is_show_hide = !this.is_show_hide;
        },
        goToCompanyList() {
            this.$router.push('/company/view/cmpid/' + this.$route.params.company_id);
        },
        goToListView() {
            this.$router.push('/businessunit/listview/cmpid/' + this.$route.params.company_id);
        },
        async getBusinessUnitData(id) {
            this.ajax_call_in_progress = true;
            try {
                let response = await this.getBusinessUnitById(id);
                if(response.status_id == 1) {
                    this.bus_edit_data = response.response;
                }
                this.ajax_call_in_progress = false;
            }
            catch(err) {
                console.log(err);
            }
        },
        goToEditView() {
            this.$router.push(`/businessunit/edit/cmpid/${this.$route.params.company_id}/${this.$route.params.id}`)
        }

    },
    mounted() {
        if(this.$route.params.id) {
            this.getBusinessUnitData(this.$route.params.id);
        }
    },
    computed: {
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
    },
    created() {
    }
}
</script>
<style scoped>
    .create-company-container {
        background-color: #fff;
        padding: 15.5px 0 50px 0;
        border-radius: 6px;
    }
    .overflow-auto {
        overflow: auto;
    }
    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
    }
    .input-field{
        min-height: 38px !important;
        padding: 9px 10px !important;
    }
</style>